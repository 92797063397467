<template>
  <div>
    <div class="breadcrumb">
      <h1>Registro de Cotizaciones</h1>
    </div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-3">
                <button
                  class="btn th-custom-color px-4 rounded"
                  @click="irACrearCotizacion"
                  :disabled="!hasPermisionNuevoCotizacion"
                >
                  Nuevo
                </button>
              </div>
              <div class="col-sm-3 offset-sm-6">
                <div class="text-right">
                  <label class="switch switch-success mr-3">
                    <input id="filterbyState"
                      type="checkbox"
                      :checked="estado"
                      v-model="estado"
                      @change="listarRegistros">
                    <span class="slider"></span>
                    <span>{{ labelActivos }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-1">
              <div class="col-sm-3">
                <div class="btn-group">
                  <span class="pt-2 mr-1">Mostrar </span>
                  <b-form-select
                    v-model="por_pagina"
                    :options="pagina_opciones"
                    @change="listarRegistros">
                  </b-form-select>
                  <span class="pt-2 ml-1"> Registros</span>
                </div>
              </div>
              <div class="col-sm-3 offset-sm-6 text-right">
                <div class="form-group row">
                  <div class="col-sm-4"></div>
                  <div class="col-sm-8">
                    <input type="text"
                     class="form-control"
                     v-model="busqueda"
                     autocomplete="off"
                     placeholder="Buscar"
                     @keyup="filtrarBusqueda">
                  </div>
                </div>
              </div>
            </div>
            <b-table
              class="table table-striped table-hover"
              :fields="fields"
              :items="listaTabla"
              :busy="isLoading"
              :per-page="por_pagina">
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
                  <strong>Cargando...</strong>
                </div>
              </template>
              <template v-if="estado" #cell(editar)="items">
                <router-link v-if="hasPermisionModificarCotizacion"
                  :to="{ name: 'cotizacion-edit', params: {id: encrypt(items.item.id)}}"
                >
                  <i
                    class="nav-icon i-Pen-5 text-success font-weight-bold"
                    style="cursor: pointer"
                  ></i>
                </router-link>
                <i v-else
                  class="nav-icon i-Pen-5 font-weight-bold"
                  style="cursor: pointer"
                ></i>
              </template>
              <template #cell(ver)>
                <i
                  class="nav-icon i-Eye font-weight-bold"
                  :class="hasPermisionVerCotizacion? 'text-warning':''"
                  style="cursor: pointer"
                ></i>
              </template>
              <template #cell(imprimir)="items">
                <i
                  @click="onClickImprimirIcon(items.item)"
                  class="nav-icon i-File-Download font-weight-bold"
                  :class="hasPermisionImprimirCotizacion? 'text-info':''"
                  style="cursor: pointer">
                </i>
              </template>
              <template v-if="estado" #cell(eliminar)="items">
                <i @click="onClickEliminarIcon(items.item)"
                  class="nav-icon i-Close-Window font-weight-bold"
                  :class="hasPermisionEliminarCotizacion? 'text-danger':''"
                  style="cursor: pointer"
                ></i>
              </template>
              <template #cell(total)="data">
                {{data.item.total | numeral('0,0.00')}}
              </template>
              <template v-slot:cell(estado)="data">
                <a
                  href="javascript:void(0)"
                  @click="onClickEstadoItem(data)"
                  :style="'background-color:'+data.item.estado.color">
                  {{data.item.estado.nombre}}
                </a>
              </template>
            </b-table>
            <b-modal id="modal-opciones"
              :title="'Seleccione para Ver'"
              hide-footer>
              <div class="row mt-4">
                <div class="col-sm-6 text-center">
                  <button class="btn th-custom-color col-sm-10"
                   @click="downloadCotizacionPDF()">
                    Cotizacíon PDF
                  </button>
                </div>
              </div>
            </b-modal>
            <b-modal id="modal-eliminar"
              :title="'Seguro que quieres eliminar la Cotización - N° '+selectedNro+'?'"
              hide-footer>
              <div class="row">
                <div class="col-sm-6">
                  <button class="col-sm-12 btn btn-danger" @click="eliminarRegistro">
                    Eliminar
                  </button>
                </div>
                <div class="col-sm-6">
                  <button class="col-sm-12 btn btn-outline-dark"
                  @click="$bvModal.hide('modal-eliminar')">Cancelar</button>
                </div>
              </div>
            </b-modal>
            <b-modal id="modal-estado"
              :title="'Seleccione el Estado'"
              hide-footer>
              <div class="row mt-4">
                <div class="col-sm-6 text-center">
                  <multiselect
                    v-model="estadoSelected"
                    :options="estados"
                    :allow-empty="false"
                    placeholder=""
                    label="nombre" track-by="id"
                    select-label=""
                    deselect-label="X"
                  >
                    <span slot="caret" v-if="!(estadoSelected===null)"></span>
                    <span slot="noResult">Sin Resultados</span>
                    <span slot="noOptions">Lista Vacia</span>
                  </multiselect>
                </div>
                <div class="col-sm-6">
                  <button
                    @click="actualizarEstado"
                    class="btn btn-success">
                    Actualizar
                  </button>
                </div>
              </div>
            </b-modal>
            <div class="row">
              <div class="col-sm-8"></div>
              <div class="col-sm-4">
                <b-pagination
                  v-model="pagina_actual"
                  :total-rows="totalFilas"
                  :per-page="por_pagina"
                  @input="listarRegistros"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapGetters } from 'vuex';

export default {
  name: 'index',
  components: {
    Multiselect,
  },
  data() {
    return {
      estado: true,
      por_pagina: 100,
      pagina_opciones: [50, 100],
      busqueda: null,
      delayTimeout: null,
      fields: [
        {
          key: 'editar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'ver', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'imprimir', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'numero', label: 'N°', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '5%' }, sortable: true,
        },
        {
          key: 'fecha', label: 'Fecha', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '7%' }, sortable: true,
        },
        {
          key: 'paciente', label: 'Paciente', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '16%' }, sortable: true,
        },
        {
          key: 'medico', label: 'Medico', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '15%' }, sortable: true,
        },
        {
          key: 'moneda', label: 'Moneda', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'total', label: 'Importe', thClass: 'th-custom-color', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'usuario', label: 'Usuario', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '15%' }, sortable: true,
        },
        {
          key: 'estado', label: 'Estado', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
      ],
      listaTabla: [],
      isLoading: true,
      pagina_actual: 1,
      totalFilas: 0,
      selectedId: null,
      selectedNro: null,
      indexSelected: null,
      estadoSelected: null,
      estados: [],
    };
  },
  created() {
    this.obtenerEstados();
    this.listarRegistros();
  },
  methods: {
    irACrearCotizacion() {
      if (this.$route.name !== 'cotizacion-create') {
        this.$router.push({ name: 'cotizacion-create' });
      }
    },
    filtrarBusqueda() {
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(this.listarRegistros, DELAY);
    },
    async listarRegistros() {
      this.isLoading = true;
      try {
        const REQUEST = {
          estado: (this.estado) ? 1 : 0,
          Filter_key: this.busqueda,
          page: this.pagina_actual,
          per_page: this.por_pagina,
        };
        const RESPONSE = await axios.get('/clinic/quotation', {
          params: REQUEST,
        });
        this.totalFilas = RESPONSE.data.data.total;
        this.listaTabla = RESPONSE.data.data.data;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    setItem(fila) {
      this.selectedId = fila.id;
      this.selectedNro = fila.numero;
    },
    eliminarRegistroLocalmente() {
      this.listaTabla = this.listaTabla.filter((item) => (
        item.id !== this.selectedId
      ));
    },
    async eliminarRegistro() {
      this.$bvModal.hide('modal-eliminar');
      try {
        this.eliminarRegistroLocalmente();
        const RESPONSE = await axios.delete(`/clinic/quotation/${this.selectedId}`);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        await this.listarRegistros();
      }
    },
    async downloadCotizacionPDF() {
      try {
        const RESPONSE = await axios.get(`/clinic/quotation/print/${this.selectedId}`, {
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        window.open(DOWNLOAD_URL, '_blank');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async obtenerEstados() {
      this.isLoading = true;
      try {
        const RESPONSE = await axios.get('/clinic/quotation/estados');
        this.estados = RESPONSE.data.data.estados;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    onClickEstadoItem(data) {
      if (this.estado === false) {
        return;
      }
      this.$bvModal.show('modal-estado');
      this.indexSelected = data.index;
      this.setItem(data.item);
      this.predeterminarEstadoEnCombo(data.item.estado.id);
    },
    predeterminarEstadoEnCombo(id) {
      const ITEM_SELECTED = this.estados.find((el) => el.id === id);
      this.estadoSelected = ITEM_SELECTED;
    },
    async actualizarEstado() {
      this.$bvModal.hide('modal-estado');
      this.listaTabla[this.indexSelected].estado.id = this.estadoSelected.id;
      this.listaTabla[this.indexSelected].estado.nombre = this.estadoSelected.nombre;
      this.listaTabla[this.indexSelected].estado.color = this.estadoSelected.color;
      try {
        const ID = this.selectedId;
        const REQUEST = {
          estado_id: this.estadoSelected.id,
        };
        const RESPONSE = await axios.post(`/clinic/quotation/estados/${ID}`, REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    encrypt(id) {
      return btoa(id);
    },
    onClickImprimirIcon(item) {
      if (this.hasPermisionImprimirCotizacion) {
        this.setItem(item);
        this.$bvModal.show('modal-opciones');
      }
    },
    onClickEliminarIcon(item) {
      if (this.hasPermisionEliminarCotizacion) {
        this.setItem(item);
        this.$bvModal.show('modal-eliminar');
      }
    },
  },
  computed: {
    ...mapGetters('main', [
      'hasPermisionNuevoCotizacion',
      'hasPermisionModificarCotizacion',
      'hasPermisionVerCotizacion',
      'hasPermisionEliminarCotizacion',
      'hasPermisionImprimirCotizacion',
    ]),
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Inactivos';
    },
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
